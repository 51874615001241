<section class="main-section">
    <div class="left-section" (click)="redirectToHome()">
        <img src="./../../../../assets/images/footer/logo_image.svg" alt="simpo_logo" />
    </div>
    <div class="middle-section">
        <div class="middle-left-side">
            <div class="middle-ui">Resources</div>
            <div class="middle-li" (click)="pricing()">Pricing</div>
            <div class="middle-li" (click)="contact()">Contact Us</div>
            <!-- <div class="middle-li">Blogs</div> -->
        </div>
        <div class="middle-right-side">
            <div class="middle-ui">Company</div>
            <!-- <div class="middle-li">About Us</div> -->
            <div class="middle-li" (click)="termsConditions()">Terms & Conditions</div>
            <div class="middle-li" (click)="privacyPolicy()">Privacy Policy</div>
            <div class="middle-li" (click)="cancellationRouting()">Cancellation</div>
            <div class="middle-li" (click)="refundRouting()">Refund Policy</div>
            <div class="middle-li" (click)="navigateAffiliate()">Affiliate Program</div>
        </div>
    </div>
    <div class="right-section">
        <div class="linkedin_logo">
            <a href="https://www.linkedin.com/company/simpo-ai/" title="LinkedIn" target="_blank">
                <img src="./../../../../assets/images/footer/linked-in.svg" alt="linkedin_logo">
            </a>
        </div>
        <div class="instagram_logo">
            <a href="https://www.instagram.com/simpo.ai/" title="Instagram" target="_blank">
                <img src="./../../../../assets/images/footer/instagram_logo.svg" alt="instagram_logo"/>
            </a>
        </div>
        <div class="twitter_logo">
            <a href="https://twitter.com/simpowebbuilder" title="Twitter" target="_blank">
                <img src="../../../assets/images/footer/new-twitter-img.png" alt="twitter_logo"  style="width: 35px;"/>
            </a>
        </div>
        <div class="facebook_logo">
            <a href="https://www.facebook.com/profile.php?id=100091262862601" title="Facebook" target="_blank">
                <img src="./../../../../assets/images/footer/facebook_logo.svg" alt="facebook_logo" />
            </a>
        </div>
    </div>
</section>