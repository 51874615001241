import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss','./mq-footer.component.scss']
})
export class FooterComponent implements OnInit{

  constructor(
    private _router : Router
  ){}

  ngOnInit() {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
  }

  termsConditions(){
    this._router.navigate(['/terms-conditions'])
  }

  privacyPolicy(){
    this._router.navigate(['/privacy-policy'])
  }
  cancellationRouting() {
    this._router.navigate(['/cancellation'])
  }
  refundRouting() {
    this._router.navigate(['/refunds'])
  }

  pricing(){
    this._router.navigate(['/pricing'])
  }
  contact(){
    this._router.navigate(['/contact-us'])
  }

  socialLinks(ev:any){
    if(ev === 'INSTA'){
      window.open('https://www.instagram.com/simpo.ai/','_blank')
    }
    else if(ev === 'TWITTER'){
      window.open('https://twitter.com/simpowebbuilder','_blank')
    }
    else if(ev === 'FACEBOOK'){
      window.open('https://www.facebook.com/profile.php?id=100091262862601','_blank')
    }
    else if(ev === 'LINKED_IN'){
      window.open('https://www.linkedin.com/company/simpo-ai/ ','_blank')
    }
  }
  navigateAffiliate()
  {
    this._router.navigate(['/affiliate-program'])
  }

  redirectToHome(){
    this._router.navigate(['/'])
  }
}
